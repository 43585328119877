<template>
	<div class="container">
		<baseTable
			ref="baseTable"
			@request="getTableData"
			:queryItem="queryItem"
			:columns="tableCols"
			:otherBtns="otherBtns"
			@rowClick="rowClick"
			showOrder
		/>
		<el-dialog
			fullscreen
			:title="dialogTitle"
			top="30vh"
			center
			:visible.sync="dialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
		>
			<formItem
				v-if="dialogVisible"
				ref="formItem"
				class="indicaForm"
				style="margin: 20px 120px"
				:formItems="dialogItems"
				@submit="this.submitForm"
				@cancel="dialogVisible = false"
				:defaultData="formModel"
				:type="formType"
			/>
		</el-dialog>
		<!-- 导入按钮的弹窗 -->
		<Upload :visible.sync="dialogImportVisible" :config="uploadConfig" />
	</div>
</template>

<script>
import baseTable from '@/views/components/components-base-table';
import formItem from '@/views/components/components-form-item/index.vue';
import Upload from '@/components/Upload/index.vue';
import { downloadByCreateA } from '@/utils/util';
import { Loading } from 'element-ui';
// import moment from 'moment';
export default {
	name: 'organizationalConfig',
	components: {
		baseTable,
		formItem,
		Upload
	},

	props: { libraryName: { type: String }, pageName: {} },
	computed: {
		// typeNameOpts() {
		// 	return this.$store.state.app?.dict?.filter((item) => item.groupId === 'staffChangeType') || [];
		// }
		// 禁用删除
		getDelDisabled() {
			return ({ isOriginalPlat }) => isOriginalPlat;
		}
	},
	data() {
		return {
			tableCols: [
				{ label: '维度', key: 'dimensionName' },
				{ label: '指标意义', key: 'meaning' },
				{ label: '指标名称', key: 'indicatorName' },
				{ label: '指标公式/定义', key: 'indicatorDefine' },
				{ label: '考核对象', key: 'objectName' },
				{ label: '统计部门', key: 'statisticalOrgName' },
				{ label: '频率', key: 'frequency' },
				{
					label: '操作',
					key: 'cz',
					render: (row) => (
						<div>
							{this.permissionControlBtns(this.pageName, 'Edit') && (
								<el-button type="text" onclick={(e) => this.handleEdit(row, e)}>
									编辑
								</el-button>
							)}
							{this.permissionControlBtns(this.pageName, 'Delete') && (
								<el-button
									type="text"
									style={'color:rgb(245, 108, 108); '}
									// disabled={this.getDelDisabled(row, inx)}
									onclick={(e) => this.handleDel(row, e)}
								>
									删除
								</el-button>
							)}
						</div>
					)
				}
			],
			inspectionObjectOpts: [
				{ dictId: 'org', dictName: '部门/组织' },
				{ dictId: 'job', dictName: '岗位' }
			],
			queryItem: [
				{
					label: '维度',
					key: 'dimensionId',
					type: 'select',
					async: () =>
						this.$axios
							.get('/v1/doRightThing/indicators/dimension/getList', {})
							.then((res) => res?.map(({ id, name, type }) => ({ dictId: id, dictName: name, type }))),
					span: 5
				},
				{
					label: '考核对象',
					key: 'inspectionObject',
					type: 'select',
					options: () => this.inspectionObjectOpts,
					placeholder: '请选择对象类别',
					async: () =>
						this.$axios
							.post('/v1/org/queryOfficeJob/list', { request: { type: 2 } })
							.then((res) => res.list?.map(({ id, jobOrOfficeName }) => ({ dictId: id, dictName: jobOrOfficeName }))),
					change: (data, item) => {
						item.forEach((item) => {
							if (item.key === 'objectId') {
								if (data.inspectionObject === 'job') {
									item.type = 'select';
									item.options = this.$store.state.setData?.options?.inspectionObject || [];
								} else if (data.inspectionObject === 'org') {
									item.type = 'tree';
									item.options = [];
								}
								this.$delete(data, 'objectId');
								this.$delete(data, 'objectName');
							}
						});
					},
					span: 4
				},
				{
					// label: '考核对象',
					key: 'objectId',
					type: 'select',
					options: [],
					// multiple: true,
					async: () => this.$axios.post('/v1/org/list', { request: {} }),
					props: { children: 'children', label: 'orgName' },
					nodeKey: 'id',
					nodeName: 'orgName',
					showKey: 'objectName',
					span: 4,
					inputStyle: 'padding: 0 8px'
				},
				{
					label: '频率',
					key: 'frequency',
					type: 'select',
					options: [
						{ dictId: '月度', dictName: '月度' },
						{ dictId: '季度', dictName: '季度' },
						{ dictId: '半年度', dictName: '半年度' },
						{ dictId: '年度', dictName: '年度' }
					],
					span: 4
				}
			],
			dialogItems: [
				{
					label: '维度',
					key: 'dimensionId',
					type: 'select',
					class: 'dimensionIdClass',
					defaultFirstOption: true,
					options: () => {
						const { setData } = this.$store.state;

						return setData.options.dimensionId || [];
					},
					optEdit: true,
					optProps: { add: '/v1/doRightThing/indicators/dimension/add', del: '/v1/doRightThing/indicators/dimension/delete', key: 'name' },
					footerRender: true,
					rules: [{ required: true, message: '请选择', trigger: 'change' }],
					span: 12
				},
				{
					label: '指标意义',
					key: 'meaning',
					type: 'input',
					span: 12
				},
				{
					label: '指标名称',
					key: 'indicatorName',
					type: 'input',
					span: 12,
					rules: [{ required: true, message: '请输入', trigger: 'change' }]
				},
				{
					label: '指标公式/定义',
					key: 'indicatorDefine',
					type: 'input',
					span: 12,
					rules: [{ required: true, message: '请输入', trigger: 'change' }]
				},
				{
					label: '考核对象',
					key: 'inspectionObject',
					type: 'select',
					options: [
						{ dictId: 'org', dictName: '部门/组织' },
						{ dictId: 'job', dictName: '岗位' }
					],
					placeholder: '请选择对象类别',
					change: (data, opt, item) => {
						item.forEach((item) => {
							if (item.key === 'objectId') {
								this.$delete(data, 'objectId');
								this.$delete(data, 'objectName');
							}
						});
					},
					rules: [{ required: true, message: '请输入', trigger: 'change' }],
					span: 6
				},
				{
					// label: '考核对象',
					key: 'objectId',
					labelWidth: '30px',
					type: ({ inspectionObject }) => {
						let tp = 'select';

						switch (inspectionObject) {
							case 'job':
								tp = 'select';
								break;
							case 'org':
								tp = 'tree';
								break;

							default:
								break;
						}
						return tp;
					},
					props: { children: 'children', label: 'orgName' },
					nodeKey: 'id',
					nodeName: 'orgName',
					showKey: 'objectName',
					multiple: true,
					options: (_, { inspectionObject }) => {
						const { options } = this.$store.state.setData;

						let opt = [];

						switch (inspectionObject) {
							case 'job':
								opt = options?.inspectionObject;
								break;
							case 'org':
								opt = options?.objectId;
								break;

							default:
								break;
						}
						return opt || [];
					},
					rules: [{ required: true, message: '请输入', trigger: 'blur' }],
					span: 18
				},
				{
					label: '统计部门',
					key: 'statisticalOrg',
					type: 'tree',
					props: { children: 'children', label: 'orgName' },
					nodeKey: 'id',
					nodeName: 'orgName',
					showKey: 'statisticalOrgName',
					options: () => this.$store.state.setData?.options?.objectId || [],
					rules: [{ required: true, message: '请输入', trigger: 'change' }],
					span: 12
				},
				{
					label: '考核频率',
					key: 'frequency',
					type: 'select',
					options: [
						{ dictId: '月度', dictName: '月度' },
						{ dictId: '季度', dictName: '季度' },
						{ dictId: '半年度', dictName: '半年度' },
						{ dictId: '年度', dictName: '年度' }
					],
					rules: [{ required: true, message: '请输入', trigger: 'change' }],
					span: 12
				}
			],
			otherBtns: [
				this.permissionControlBtns(this.pageName, 'Export') && {
					label: '导出',
					icon: 'el-icon-download',
					style: { float: 'right' },
					click: () => {
						downloadByCreateA(
							`/v1/doRightThing/indicators/exportIndicators?params=${encodeURIComponent(
								JSON.stringify({ libraryName: this.libraryName })
							)}`
						);
					}
				},
				this.permissionControlBtns(this.pageName, 'Import') && {
					label: '导入',
					icon: 'el-icon-upload',
					style: { float: 'right' },
					click: () => {
						this.dialogImportVisible = true;
					}
				},
				this.permissionControlBtns(this.pageName, 'Add') && {
					label: '新增指标',
					icon: 'el-icon-plus',
					style: { float: 'right' },
					click: () => {
						this.dialogTitle = '新增指标';
						this.formType = 'edit';
						this.formModel = {};
						this.dialogVisible = true;
					}
				}
			].filter((v) => v),
			dialogVisible: false,
			formModel: {},
			dialogTitle: '添加',
			formType: 'edit',
			options: {},
			// 导入配置
			uploadConfig: {
				dictId: 'importIndicatorsInfo',
				groupId: 'importTemplate',
				importUrl: '/v1/doRightThing/indicators/importIndicators',
				successMsg: '导入成功',
				callBack: () => {
					this.$refs.baseTable?.handleSearch();
					this.dialogImportVisible = false;
				}
			},
			dialogImportVisible: false
		};
	},
	created() {},
	mounted() {},
	methods: {
		getTableData(params, callback) {
			if (params?.request) {
				params.request.libraryName = this.libraryName;
			}
			callback(this.$axios.post('/v1/doRightThing/indicators/getList', params));
		},
		// 编辑
		handleEdit(row, e) {
			e.stopPropagation();
			this.formType = 'edit';
			this.dialogTitle = '编辑';
			if (row.objectId && typeof row.objectId === 'string') {
				row.objectId = row.objectId.split(',');
			}
			this.formModel = { ...row };
			this.dialogVisible = true;
		},
		handleDel(row, e) {
			e.stopPropagation();
			this.$confirm('是否确定删除?', '删除提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$axios
					.post('/v1/doRightThing/indicators/delete', {
						request: { ids: [row.id] },
						sign: ''
					})
					.then((res) => {
						if (res && res.code === 0) {
							this.$message.success('删除成功!');
							this.$refs.baseTable?.handleSearch();
						}
					});
			});
		},
		// 提交
		submitForm(form) {
			form.validate((valid) => {
				if (valid) {
					if (this.formModel?.objectId) {
						if (Array.isArray(this.formModel.objectId)) {
							this.formModel.objectId = this.formModel.objectId.join(',');
						}
					}
					const loading = Loading.service({ target: '.formItem' });

					this.$axios
						.post('/v1/doRightThing/indicators/save', { request: { ...this.formModel, libraryName: this.libraryName } })
						.then((res) => {
							loading.close();
							if (res.code) {
								return;
							}
							this.dialogVisible = false;
							this.$message.success('保存成功');
							this.$refs.baseTable?.handleSearch();
						})
						.catch(() => {
							this.dialogVisible = false;
						});
				}
			});
		},
		rowClick(row) {
			this.formType = 'view';
			this.dialogTitle = '详情';
			if (row.objectId && typeof row.objectId === 'string') {
				row.objectId = row.objectId.split(',');
			}
			this.formModel = { ...row };
			this.dialogVisible = true;
		}
	}
};
</script>
<style lang="scss">
.container {
	// padding: 20px;
	.indicaForm {
		.formClass {
			label[for='objectId']::before {
				content: '-';
				color: #333;
			}
		}
	}
}
</style>
