<template>
	<div class="app-container">
		<el-tabs v-model="activeName">
			<el-tab-pane label="公司指标库" name="1" v-if="permissionControl('CompanyIndexLibrary')">
				<query ref="indicatorsOfLibrary1" libraryName="公司级指标库" v-if="activeName === '1'" pageName="CompanyIndexLibrary" />
			</el-tab-pane>
			<el-tab-pane label="部门指标库" name="2" v-if="permissionControl('DepartmentIndexLibrary')">
				<query ref="indicatorsOfLibrary2" libraryName="部门级指标库" v-if="activeName === '2'" pageName="DepartmentIndexLibrary" />
			</el-tab-pane>
			<el-tab-pane label="岗位指标库" name="3" v-if="permissionControl('JobIndexLibrary')">
				<query ref="indicatorsOfLibrary3" libraryName="岗位指标库" v-if="activeName === '3'" pageName="JobIndexLibrary" />
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import query from './query.vue';
export default {
	name: 'indicatorsOfLibrary',
	components: { query },

	data() {
		return { activeName: '' };
	},
	watch: {
		activeName() {
			console.log(this.activeName, 'activeName');
		}
	},

	mounted() {
		const data = JSON.parse(sessionStorage.getItem('indicatorsOfLibraryData'));
		const { activeName, listQuery } = data || {};

		this.activeName = activeName || '1';
		if (listQuery) {
			this.listQuery = listQuery;
		}
		// 设置tab默认
		this.setActiveTab(this.$route.name, [
			{ name: '1', key: 'CompanyIndexLibrary' },
			{ name: '2', key: 'DepartmentIndexLibrary' },
			{ name: '3', key: 'JobIndexLibrary' }
		]);
	},
	beforeRouteLeave(to, from, next) {
		sessionStorage.setItem(
			'indicatorsOfLibraryData',
			JSON.stringify({
				activeName: this.activeName
				// listQuery: this.$refs[`indicatorsOfLibrary${this.activeName}`].listQuery
			})
		);
		next();
	}
};
</script>

<style lang="scss" scoped></style>
