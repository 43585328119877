<template>
	<el-dialog width="40%" title="导入" v-loading="uploading" :visible.sync="visible" :before-close="handleClose" append-to-body>
		<span slot="title" class="dialog-title">导入</span>
		<div class="import_dialog">
			<div class="import_dialog_btn">
				<el-upload
					class="upload-demo"
					action
					:show-file-list="false"
					:http-request="exportExcel"
					:multiple="false"
					:file-list="fileList"
					:on-exceed="handleExceed"
				>
					<el-button type="primary" icon="el-icon-upload" round>导入Excel</el-button>
				</el-upload>
			</div>
			<!-- <el-progress :percentage="percentage"></el-progress> -->
			<div class="import_dialog_desc">
				<span class="import_dialog_desc_download_text" @click="handleDownloadTemplate">Excel模板下载</span>
				<span>（注：导入操作请务必以下载的模板为准！）</span>
			</div>
		</div>
	</el-dialog>
</template>

<script>
export default {
	name: 'UploadPage',
	props: {
		visible: { type: Boolean, default: false },
		config: { type: Object, default: () => {} }
	},

	data() {
		return { uploading: false, fileList: [] };
	},

	mounted() {},

	methods: {
		exportExcel(event) {
			// let file=fileObj.file;
			if (!event.file || !event.file.name) {
				this.$message.error('文件有误');
				return;
			}
			if (
				['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].indexOf(event.file.type) === -1 &&
				!event.file.name.includes('.xl')
			) {
				this.$message.error('请上传正确Excel文件');
				return;
			}
			const params = new FormData();

			params.append('imgFile', event.file);
			const type = 'multipart/form-data;boundary=--------------------56423498738365';
			const loading = this.$loading({
				lock: true,
				text: '导入中,请稍后...',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});

			this.$axios
				.post(this.config.importUrl, params, {
					headers: { 'Content-Type': type }
				})
				.then((res) => {
					loading.close();
					if (res.code) {
						return;
					}
					// if (res && res.code === 0) {
					this.$message.success(this.config.successMsg || 'success');
					this.config.callBack({ ...res, name: event.file?.name });
					// }
				})
				.catch(() => {});
			this.fileList = [];
		},
		handleExceed() {
			this.$message.warning('请选择单个文件');
		},
		getTemplate(name) {
			const dictObj = this.$store.state.app.dict.filter((item) => item.groupId === this.config.groupId).filter((el) => el.dictId === name);

			return dictObj[0]?.remark;
		},
		// 下载excel模板
		handleDownloadTemplate() {
			window.location.href = this.getTemplate(this.config.dictId);
		},
		handleClose() {
			this.$emit('update:visible', false);
		}
	}
};
</script>

<style lang="scss" scoped>
.import_dialog {
	width: 100%;

	.import_dialog_btn {
		text-align: center;

		margin: 0 auto;

		.import_dialog_btn_inner {
			width: 300px;
			color: #fff;
			height: 100%;
			display: block;
		}
	}

	.import_dialog_desc {
		text-align: center;
		margin-top: 15px;

		.import_dialog_desc_download_text {
			color: rgb(64, 158, 255);
			cursor: pointer;
		}
	}
}
::v-deep.el-dialog__header {
	text-align: center;

	.dialog-title {
		font-size: 18px;
		letter-spacing: 0;
		line-height: 24px;
		text-align: center;
	}
}
</style>
